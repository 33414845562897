import { environment } from '../../../environments/environment';

export function ep(ep: string) {
  return `${environment.apiUrl}/api/${ep}`;
}

export const AccountEP = {
  SignUp: () => ep('account/signup'),
  ResendConfirmation: () => ep('account/resend-confirmation'),
  EmailConfirm: () => ep('account/email-confirm'),
  ChangeEmail: () => ep('account/change-email'),
  ChangeEmailConfirm: () => ep('account/change-email-confirm'),
  ChangeEmailAndConfirmAccount: () =>
    ep('account/change-email-and-confirm-account'),
  ChangePassword: () => ep('account/change-password'),
  ResetPassword: () => ep('account/reset-password'),
  ConfirmAccount: () => ep('account/confirm'),
};

export const UsersEP = {
  All: () => ep('users'),
  ById: (id: string) => `${UsersEP.All()}/${id}`,
  Employee: (id: string) => `${UsersEP.ById(id)}/employee`,
  CreateEmployee: () => `${UsersEP.All()}/employee`,
  Student: (id: string) => `${UsersEP.ById(id)}/student`,
  CreateStudent: () => `${UsersEP.All()}/student`,
  EmployeeRoles: (id: string) => `${UsersEP.Employee(id)}/roles`,
  TeacherClasses: (id: string) => `${UsersEP.ById(id)}/teacher/classes`,
} as const;

export const RoleDataEP = {
  Base: () => ep('role-data'),
  Permissions: () => `${RoleDataEP.Base()}/permissions`,
  Traits: () => `${RoleDataEP.Base()}/traits`,
  User: (id: string) => ep(`users/${id}/role-data`),
} as const;

export const UtilitiesEP = {
  Time: () => ep('utilities/time'),
} as const;

export const FileEP = {
  POST: () => ep('attachments/temporary'),
} as const;

export const AddressEP = {
  Countries: () => ep('addresses/countries'),
  Regions: () => ep('addresses/regions'),
  Cities: () => ep('addresses/cities'),
} as const;

export const JoinApplicationEP = {
  All: () => ep('join-applications'),
  ById: (id: string) => `${JoinApplicationEP.All()}/${id}`,
  Send: (id: string) => `${JoinApplicationEP.ById(id)}/send`,
  Reject: (id: string) => `${JoinApplicationEP.ById(id)}/reject`,
  Approve: (id: string) => `${JoinApplicationEP.ById(id)}/approve`,
  Correction: (id: string) => `${JoinApplicationEP.ById(id)}/correction`,
  // EducationalLevelBase: () => `${JoinApplicationEP.All()}/education-levels`,
  // EducationalLevelTemplates: () =>
  //   `${JoinApplicationEP.EducationalLevelBase()}/templates`,
  // EducationalLevelSchedules: () =>
  //   `${JoinApplicationEP.EducationalLevelBase()}/schedules`,
  // EducationalLevelScheduleById: (id: string) =>
  //   `${JoinApplicationEP.EducationalLevelBase()}/schedules/${id}`,
  // EducationalLevelScheduleTask: (scheduleId: string) =>
  //   `${JoinApplicationEP.EducationalLevelScheduleById(scheduleId)}/task`,
  // EducationalLevelScheduleTaskAnswer: (scheduleId: string) =>
  //   `${JoinApplicationEP.EducationalLevelScheduleById(scheduleId)}/task-answer`,
  // Orders: () => `${JoinApplicationEP.EducationalLevelBase()}/orders`,
  // Order: (orderId: string) =>
  //   `${JoinApplicationEP.EducationalLevelBase()}/orders/${orderId}`,
} as const;

export const RolesEP = {
  All: () => ep('roles'),
  ById: (id: string) => `${RolesEP.All()}/${id}`,
} as const;

export const SubjectsEP = {
  All: () => ep('subjects'),
  ById: (id: string) => `${SubjectsEP.All()}/${id}`,
} as const;

export const IndividualStudyPlansEP = {
  All: () => ep('individual-study-plans'),
  ById: (id: string) => `${IndividualStudyPlansEP.All()}/${id}`,
  StudentAccess: (id: string) =>
    `${IndividualStudyPlansEP.ById(id)}/student-access`,
};

export const IndividualStudyPlanItemsEP = {
  All: () => ep('individual-study-plan-items'),
  ById: (id: string) => `${IndividualStudyPlanItemsEP.All()}/${id}`,
  Start: (id: string) => `${IndividualStudyPlanItemsEP.ById(id)}/start-task`,
  FinishInterview: (id: string) =>
    `${IndividualStudyPlanItemsEP.ById(id)}/finish-interview`,
  Reject: (id: string) => `${IndividualStudyPlanItemsEP.ById(id)}/reject`,
  CorrectionNeeded: (id: string) =>
    `${IndividualStudyPlanItemsEP.ById(id)}/request-task-correction`,
  SubmitTaskAnswer: (id: string) =>
    `${IndividualStudyPlanItemsEP.ById(id)}/submit-task-answer`,
  AssessTask: (id: string) =>
    `${IndividualStudyPlanItemsEP.ById(id)}/assess-task`,
  InterviewDate: (id: string) =>
    `${IndividualStudyPlanItemsEP.ById(id)}/interview-date`,
};

export const IndividualStudyPlanAssignmentVariantsEP = {
  All: () => ep('assignment-variants'),
  ById: (id: string) =>
    `${IndividualStudyPlanAssignmentVariantsEP.All()}/${id}`,
};

export const AcademicYearsEP = {
  All: () => ep('academic-years'),
  ById: (id: string) => `${AcademicYearsEP.All()}/${id}`,
};

export const IndependentWorkEP = {
  All: () => ep('independent-works'),
  ById: (id: string) => `${IndependentWorkEP.All()}/${id}`,
};

export const MovementEp = {
  All: () => ep('movement'),
  transfer: () => `${MovementEp.All()}/transfer`,
  detach: () => `${MovementEp.All()}/detach`,
  attach: () => `${MovementEp.All()}/attach`,
} as const;

export const ReportsEp = {
  All: () => ep('report'),
  StudentOperations: () => `${ReportsEp.All()}/student-operations`,
  EvaluationByClassLevel: (classLevel: number) =>
    `${ReportsEp.All()}/evaluation/${classLevel}`,
  AlphabeticalBook: () =>  `${ReportsEp.All()}/alphabetical-book`
} as const;

export const PaymentsEp = {
  paymentData: () => ep('payment-data'),
  pay: () => ep('payment/liqpay'),
};

export const EducationalCentersEp = {
  All: () => ep('educational-centers'),
  ById: (id: string) => `${EducationalCentersEp.All()}/${id}`,
};
